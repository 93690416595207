.label_dropdown {
  border-radius: 25% 25% 0 0;
}

.input {
  display: flex;

  height: 100%;
  flex-grow: 1;

  transition: all 0.3s ease-out;
}

.label:hover .buttonClose,
.label:hover .buttonSearch {
  background: var(--color-primary-008);
  color: var(--color-primary);
}

.buttonClose,
.buttonSearch {
  display: flex;

  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: transparent;

  color: #a8a8aa;

  transition: all 0.3s ease-out;
}

.buttonClose_active,
.buttonSearch_active {
  color: var(--color-primary);
}

.icon {
  height: 24px;
  width: 24px;
}
