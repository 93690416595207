@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-h1 {
    @apply text-[56px] font-[500] leading-[68px];
  }
  .text-h2 {
    @apply text-[32px] font-[500] leading-[40px];
  }
  .text-h3 {
    @apply text-[22px] font-[500] leading-[28px];
  }
  .text-h4 {
    @apply text-[16px] font-[500] leading-[24px] tracking-[0.024px];
  }
  .text-h5 {
    @apply text-[14px] font-[500] leading-[20px] tracking-[0.014px];
  }

  .text-dl {
    @apply text-[57px] leading-[64px] font-[400];
  }
  .text-dm {
    @apply text-[45px] leading-[52px] font-[400];
  }
  .text-ds {
    @apply text-[36px] leading-[44px] font-[400];
  }

  .text-hl {
    @apply text-[32px] leading-[40px] font-[400];
  }
  .text-hm {
    @apply text-[28px] leading-[36px] font-[400];
  }
  .text-hs {
    @apply text-[24px] leading-[32px] font-[400];
  }

  .text-tl {
    @apply text-[22px] leading-[28px] font-[400];
  }
  .text-tm {
    @apply text-[16px] leading-[24px] font-[500];
  }
  .text-ts {
    @apply text-[14px] leading-[20px] font-[400];
  }

  .text-ll {
    @apply text-[14px] leading-[20px] font-[500];
  }
  .text-lm {
    @apply text-[12px] leading-[16px] font-[500] tracking-[0.06px];
  }
  .text-ls {
    @apply text-[11px] leading-[16px] font-[500];
  }

  .text-bl {
    @apply text-[16px] leading-[24px] font-[400];
  }
  .text-bm {
    @apply text-[14px] leading-[20px] font-[400];
  }
  .text-bs {
    @apply text-[12px] leading-[16px] font-[400];
  }

  .ellipsis {
    @apply overflow-hidden whitespace-nowrap text-ellipsis;
  }

  .elevation-1 {
    @apply shadow-[0_1px_3px_1px_rgba(0,0,0,0.15),0_1px_2px_0_rgba(0,0,0,0.30)];
  }
  .elevation-2 {
    @apply shadow-[0_2px_6px_2px_rgba(0,0,0,0.05),0_1px_2px_0_rgba(0,0,0,0.10)];
  }
  .elevation-3 {
    @apply shadow-[0_1px_3px_0_rgba(0,0,0,0.30),0_4px_8px_3px_rgba(0,0,0,0.15)];
  }
  .elevation-4 {
    @apply shadow-[0_2px_3px_0_rgba(0,0,0,0.30),0_6px_10px_4px_rgba(0,0,0,0.15)];
  }
  .elevation-5 {
    @apply shadow-[0_8px_12px_6px_rgba(0,0,0,0.15)];
  }
}
