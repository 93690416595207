html {
  font-size: 1em;

  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', 'Times', sans-serif;

  font-size: 1rem;
  line-height: 1.125;
  font-weight: 400;
  color: var(--color-dark);

  background-color: var(--color-bg-light);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

#root {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  height: 100vh;
}

.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  width: 100%;
  max-height: 100vh;
  overflow: hidden auto;
}

.container {
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  height: 100%;

  max-width: var(--width-large);
  width: 100%;
}

.link {
  color: var(--color-blue);

  user-select: none;
  cursor: pointer;
}

.hidden {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  height: 12px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fbf8fd;
}

::-webkit-scrollbar-thumb {
  background: #dbd9dd;
}
