.quill {
  display: flex;

  flex-direction: column-reverse;
  gap: 8px;
}

.ql-editor {
  padding: 0;
  border: 0;

  overflow: hidden;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 0;
}

.ql-toolbar.ql-snow {
  border: 0;
  background-color: #fbf8fd;
  border-radius: 8px;
}

.ql-container {
  font-family: inherit;
}

.ql-container.ql-snow {
  border: 0;
}

.ql-snow .ql-editor h1 {
  font-size: 30px;
  font-weight: 700;
}

.ql-snow .ql-editor h2 {
  font-size: 24px;
  font-weight: 700;
}

.ql-snow .ql-editor h3 {
  font-size: 20px;
  font-weight: 500;
}

.ql-editor p,
.ql-container li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1b1b1f;
}
