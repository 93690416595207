.description {
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
