:root {
  /* colors */
  /* TODO add colors from tailwind config */
  --color-primary: #3d5ba9;
  --color-primary-008: rgba(103, 80, 164, 0.08);
  --color-primary-012: rgba(103, 80, 164, 0.12);

  --color-on-surface: #1b1b1f;
  --color-on-surface-transparent: rgba(29, 27, 32, 0.12);
  --color-on-surface-variant: #45464f;

  --color-dark: #2d3c59;
  --color-light: #ffffff;
  --color-error: #f01c35;
  --color-warning: #ff334b;

  --color-purple: #8833ff;
  --color-light-purple: #ededff;
  --color-dark-purple: #7520eb;
  --color-blue: #4971ff;
  --color-light-blue: #009cf4;
  --color-light-light-blue: #f1f7ff;
  --color-dark-blue: #6865ff;
  --color-green: #25bd33;
  --color-dark-green: #0f971c;
  --color-light-green: #11cb72;
  --color-pink: #e62e7b;
  --color-orange: #ff6633;
  --color-teal-blue: #33bfff;
  --color-red: #e62e2e;
  --color-yellow: #ffcb33;
  --color-bronze: #cc7429;
  --color-tiffany: #2ee6ca;

  --color-overlay: rgba(0, 0, 0, 0.6);

  /* element hight */
  --height-search-field: 56px;
  --height-select-option: 56px;

  /* element width */
  --width-sidebar: 280px;
  --width-header: 0px;
  --width-footer: 0px;

  /* screen width */
  --width-large: 1440px;
  --width-medium: 1280px;
  /* --width-medium: 905px; */
  /* --width-small: 600px; */
  /* --width-extra-small: 360px; */

  /* z-index */
  --z-index-hidden: -1;
  --z-index-modal: 10;
}
